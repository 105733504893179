<template>
  <forgot-password-confirm></forgot-password-confirm>
</template>

<script>
const storageKeyEmailForgotPassword =
  process.env.VUE_APP_STORAGE_KEY_FORGOT_PASS || "_forgot_password_email";

import Vue from "vue";
import ForgotPasswordConfirm from "./../../components/security/forgot-password-confirm";
export default {
  beforeRouteEnter(to, from, next) {
    if (Vue.prototype.$auth.check()) {
      next({ name: "dashboard" });
    } else {
      if (!sessionStorage.getItem(storageKeyEmailForgotPassword)) {
        next({ name: "forgot-password" });
      } else {
        next();
      }
    }
  },
  data: () => ({}),
  components: {
    ForgotPasswordConfirm,
  },
};
</script>