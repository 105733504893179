<template>
  <v-app id="register-verify" class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form autocomplete="off" @submit.prevent="recover">
              <v-card
                max-width="800"
                elevation="0"
                class="mx-auto py-0 mb-2"
                rounded="0"
              >
                <v-row no-gutters class="shadow-one">
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="third d-flex"
                  >
                    <v-card
                      elevation="0"
                      class="py-0 mx-auto my-auto"
                      rounded="0"
                      max-width="400"
                      color="rgba(255,255,255,0)"
                    >
                      <v-img
                        width="230"
                        :src="require('@/assets/img/forgot-password.png')"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="white"
                    :sm="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                  >
                    <v-card
                      elevation="0"
                      class="pt-4 mx-auto mb-5"
                      rounded="0"
                      max-width="400"
                    >
                      <v-card-text class="mb-0 pb-2 px-8 text-center">
                        <div class="d-flex justify-center">
                          <div class="mb-5">
                            <v-img
                              :src="require('@/assets/img/logo.png')"
                              width="60"
                            ></v-img>
                          </div>
                        </div>
                        <div class="text-h6 primary--text">
                          <span class="font-weight-bold">{{
                            $t("security.forgot_password.reset.title")
                          }}</span>
                        </div>
                        <div class="text-body-2 grey--text">
                          {{ $t("security.forgot_password.reset.description") }}
                        </div>
                      </v-card-text>
                      <v-card-text class="mt-2 mb-1 pb-0 px-8">
                        <div class="pb-4">
                          <span
                            class="text-subtitle-2 font-weight-medium"
                            >{{
                              $t("security.forgot_password.reset.help")
                            }}</span
                          >
                        </div>
                        <div class="mb-5">
                          <span class="mr-1 text-subtitle-2"
                            ><span class="font-weight-bold">{{
                              email
                            }}</span></span
                          >
                          <router-link
                            :to="{ name: 'forgot-password' }"
                            class="text-subtitle-2"
                            ><span class="font-weight-bold">{{
                              $t("security.btn.forgot_password.change_email")
                            }}</span></router-link
                          >
                        </div>
                        <v-text-field
                          append-icon="mdi-shield-key-outline"
                          name="email"
                          v-model="form.otp"
                          :label="`${$t('security.fields.otp.title')} *`"
                          autocomplete="off"
                          outlined
                          dense
                          maxlength="6"
                          @input="delayTouch($v.form.otp)"
                          @blur="$v.form.otp.$touch"
                          :error-messages="otpErrors"
                        ></v-text-field>
                        <v-text-field
                          append-icon="mdi-lock-outline"
                          name="password"
                          v-model="form.password"
                          :label="`${$t(
                            'security.fields.new_password.title'
                          )} *`"
                          type="password"
                          autocomplete="off"
                          outlined
                          dense
                          class="mb-1 font-weight-bold"
                          maxlength="30"
                          @input="delayTouch($v.form.password)"
                          @blur="$v.form.password.$touch"
                          :error-messages="passwordErrors"
                        ></v-text-field>
                        <v-text-field
                          append-icon="mdi-lock-outline"
                          name="confirmPassword"
                          v-model="form.confirmPassword"
                          :label="`${$t(
                            'security.fields.new_password.confirm'
                          )} *`"
                          type="password"
                          autocomplete="off"
                          outlined
                          dense
                          class="mb-1 font-weight-bold"
                          maxlength="30"
                          @input="delayTouch($v.form.confirmPassword)"
                          @blur="$v.form.confirmPassword.$touch"
                          :error-messages="confirmPasswordErrors"
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="px-8 py-0 mt-2">
                        <v-btn
                          color="primary"
                          block
                          type="submit"
                          :loading="loading"
                          :disabled="$v.form.$invalid"
                          >{{
                            $t("security.btn.forgot_password.submit")
                          }}</v-btn
                        >
                      </v-card-actions>
                      <v-card-text class="text-center">
                        <router-link
                          :to="{ name: 'forgot-password' }"
                          class="grey--text"
                        >
                          {{ $t("security.btn.resend_otp.description") }}
                        </router-link>
                      </v-card-text>
                      <v-card-actions class="mb-2 pb-0 mt-8 px-2">
                        <v-spacer></v-spacer>
                        <lang :btnOptions="{ 'x-small': true }"></lang>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <copyright></copyright>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Lang from './../commons/lang';
import { mapActions } from "vuex";
import {
  required,
  maxLength,
  minLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import Copyright from "./coypright";

const storageKeyEmailForgotPassword =
  process.env.VUE_APP_STORAGE_KEY_FORGOT_PASS || "_forgot_password_email";

export default {
  created() {
    this.email = sessionStorage.getItem(storageKeyEmailForgotPassword);
  },
  mixins: [FormDelayTouchMixin],
  data: () => ({
    form: {
      otp: null,
      password: null,
      confirmPassword: null
    },
    loading: false,
    email: null,
  }),
  metaInfo() {
    return {
      title: this.$t('security.forgot_password.reset.title')
    }
  },
  validations: {
    form: {
      otp: {
        required,
        minLength: minLength(6),
        matchOtp: helpers.regex("otp", /^[0-9]{6}$/),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        matchPassword: helpers.regex(
          "pass",
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/
        ),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    async recover() {
      const sessionRedirectPath = sessionStorage.getItem("_redirect_path");
      let message = "security.fields.otp.invalid";

      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({
          message: this.$t(message),
          centered: true,
          right: false,
        });
        return;
      }

     

      this.loading = true;
      try {
         await this.$recaptchaLoaded();
        const token = await this.$recaptcha('forgot_password');

        await this.request({
          url: "/api/auth/password-recovery/confirm",
          data: {
            email: this.email,
            otp: this.form.otp,
            password: this.form.password,
          },
          headers: {
            recaptcha: token,
          },
          method: "POST",
          messages: {
            500: true,
            404: this.$t("security.error.email.not_found"),
            422: this.$t('security.fields.otp.invalid')
          },
          notification: {
            right: false,
            centered: true,
          },
        });

        sessionStorage.removeItem(storageKeyEmailForgotPassword);

        // redirect to password reset confirm
        this.$router.push(sessionRedirectPath ? sessionRedirectPath : this.$i18n.getRouteLink("dashboard"));

        sessionStorage.removeItem('_redirect_path');
      } catch (error) {
        // eempty
      }
      this.loading = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    otpErrors() {
      const errors = [];

      if (!this.$v.form.otp.$dirty) return errors;

      !this.$v.form.otp.required &&
        errors.push(this.$t("security.fields.otp.required"));
      !this.$v.form.otp.minLength &&
        errors.push(this.$t("security.fields.otp.invalid"));
      !this.$v.form.otp.matchOtp &&
        errors.push(this.$t("security.fields.otp.invalid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.form.password.$dirty) return errors;

      !this.$v.form.password.required &&
        errors.push(this.$t("security.fields.password.required"));

      !this.$v.form.password.minLength &&
        errors.push(
          this.$t("security.fields.password.min_length", {
            min: this.$v.form.password.$params.minLength.min,
          })
        );

      !this.$v.form.password.maxLength &&
        errors.push(
          this.$t("security.fields.password.max_length", {
            max: this.$v.form.password.$params.maxLength.max,
          })
        );

      !this.$v.form.password.matchPassword &&
        errors.push(this.$t("security.fields.password.invalid"));

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];

      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$t("security.fields.confirm_password.same_as"));

      return errors;
    },
  },
  components: {
    Copyright,
    Lang
  },
};
</script>